import React, { Component } from "react";
import "./Main.css";
import Channel from "../Compoments/Channel";
import InputMask from 'react-input-mask';
import EstadosCidades from '../estados-cidades.json';
import ReCAPTCHA from "react-google-recaptcha";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
      telefone: "",
      email: "",
      empresa: "",
      estado: "",
      cidade: "",
      msg: "",
      recaptcha: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.renderCidades = this.renderCidades.bind(this);
    this.submit = this.submit.bind(this);
    this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this);
  }

  async componentDidMount() {
    setTimeout(() => (document.getElementById("root").style.display = ""), 50);
  }

  getOptionsEstadoHtml() {
    return EstadosCidades.estados.map(estado => <option value={estado.nome}>{estado.nome}</option>);
  }

  handleChange(event){
    this.setState({[event.target.name] : event.target.value})
  }

  submit() {
    if (!this.state.recaptcha) {
      alert("ReCAPTCHA deve ser confirmado!");
      return;
    }
    if (
      !this.state.nome ||
      !this.state.telefone ||
      !this.state.email ||
      !this.state.estado ||
      !this.state.cidade
    ) {
      alert("Preencha todos os campos obrigatórios!");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Methods", "GET, POST, OPTIONS, PUT, PATCH, DELETE");
    // prd
    fetch('https://olp3jxvjo2.execute-api.us-east-1.amazonaws.com/default/flip-publicidade-legal-email-prod-email', {
    // stg
    //fetch('https://wy6qkrhik3.execute-api.us-east-1.amazonaws.com/default/flip-publicidade-legal-email-hlg-email', {
      method: 'POST',
      headers: myHeaders,
      mode: "no-cors",
      body: JSON.stringify(
        {
          nome: this.state.nome,
          telefone: this.state.telefone,
          email: this.state.email,
          empresa: this.state.empresa,
          estado: this.state.estado,
          cidade: this.state.cidade,
          msg: this.state.msg,
          password: "pWasEdsAdaqFfgHqWxXcv"
        }
      )
    })
    .then((response) => {
      this.props.history.push('/conclusao');
    })
    .catch((error) => {
      alert('Erro ao enviar o contato!');
    })
  }

  renderCidades() {
    if (!this.state.estado) {
      return <select className="legal-input-100"></select>
    }
    let estado = EstadosCidades.estados.find(estado => {
      if (estado.nome === this.state.estado) {
        return estado.cidades;
      }
    });
    return <select className="legal-input-100" name="cidade" onChange={this.handleChange}><option></option>{estado.cidades.map(cidade => <option>{cidade}</option>)}</select>
  }

  onChangeRecaptcha(result) {
    this.setState({recaptcha: false});
    if (result) {
      this.setState({recaptcha: true});
    }
  }

  render() {
    let selectEstadoHtml = <select className="legal-input-100" name="estado" value={this.state.estado} onChange={this.handleChange}><option></option>{this.getOptionsEstadoHtml()}</select>
    
    return (
      <div className="main">
        <h1>Publicação de balanços na NSC</h1>
        <h2>
          Transparência empresarial nos jornais de maior
          audiência e credibilidade do Estado
        </h2>
        <h3>Acesse os jornais abaixo:</h3>
        <hr />
        <div className="row channels">
          <div className="column">
            <Channel
              image="https://diariocatarinense-pl.presslab.com.br/capa.jpg"
              link="https://diariocatarinense-pl.presslab.com.br/"
            />
          </div>
          <div className="column">
            <Channel
              image="https://anoticia-pl.presslab.com.br/capa.jpg"
              link="https://anoticia-pl.presslab.com.br/"
            />
          </div>
          <div className="column">
            <Channel
              image="http://jornaldesantacatarina-pl.presslab.com.br/capa.jpg"
              link="http://jornaldesantacatarina-pl.presslab.com.br/"
            />
          </div>
        </div>
        <div className="row">
          <h3>O que é publicidade legal?</h3>
          <hr />
          <p className="legal-message">Assim como o próprio nome indica, a publicidade legal consiste na divulgação de informações e documentos de interesse público e determinados por lei, como atas, balanços patrimoniais e manifestos. Pautada em apresentar com transparência as atividades realizadas pelas esferas pública e privada, conforme exige a Constituição, essa forma de publicidade é também uma excelente aliada na oportunidade de negócios, com maior segurança e solidez.</p> 
          <br />
          <p>Além disso, é uma importante ferramenta na hora de realizar fusões, vendas e até mesmo busca por novos investidores, pois reforça a credibilidade das empresas e instituições tanto com órgãos fiscalizadores, como com a população geral. Por isso, é fundamental garantir a divulgação dessas informações tanto em Diários Oficiais quanto em veículos de grande abrangência e relevância.</p>
          <div className="button">
          <a target="_blank" href="https://negociossc.com.br/blog/categoria/publicidade-legal/"><button className="legal-button">Quero saber mais sobre Publicidade Legal!</button></a>
          </div>
          <h3>Precisa publicar? Deixe seus dados que entraremos em contato</h3>
          <hr />

          <div className="legal-form-content">

          <div className="legal-left-60">
            <label className="legal-form-label-100">Nome:</label>
            <input type="text" className="legal-input-100" name="nome" onChange={this.handleChange}></input>
          </div>

          <div className="legal-right-40">
            <label className="legal-form-label-100">Empresa:</label>
            <input type="text" className="legal-input-100" name="empresa" onChange={this.handleChange}></input>
          </div>
            

            <div className="legal-left-50">
              <label className="legal-form-label-100">Telefone:</label>
              <InputMask className="legal-input-100" mask="(99) 99999-9999" name="telefone" onChange={this.handleChange}></InputMask>
            </div>

            <div className="legal-right-50">
              <label className="legal-form-label-100">Email:</label>
              <input type="text" className="legal-input-100" name="email" onChange={this.handleChange}></input>
            </div>

            <div className="legal-left-50">
              <label className="legal-form-label-100">Estado:</label>
              {selectEstadoHtml}
            </div>
            <div className="legal-right-50">
              <label className="legal-form-label-100">Cidade:</label>
              {this.renderCidades()}
            </div>
            <label className="legal-form-label-100">Mensagem</label>
            <textarea type="textarea" className="legal-textarea-100" name="msg" onChange={this.handleChange} rows="3"></textarea>

              <div className="recaptcha">

                <ReCAPTCHA onChange={this.onChangeRecaptcha} sitekey="6LcH-zEaAAAAAKcaRnNHB1dyb1ARY9AdnLwk4HZ_"/>

              </div>

            <div className="enviar">

              <button className="legal-button" onClick={this.submit}>Solicitar contato</button>

            </div>
              
            
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
