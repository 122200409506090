import React, { Component } from "react";
import "./Conclusion.css";

class Conclusion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    setTimeout(() => (document.getElementById("root").style.display = ""), 50);
  }

  render() {
    return (
      <div>
        <div className="conclusion-msg">Obrigado por nos informar seus dados, em breve entraremos em contato!</div>

        <div className="whatsapp">
          <button
            className="whatsapp-button"
            onClick={() =>
              window.open(
                "http://api.whatsapp.com/send?1=pt_BR&phone=5548991885651"
              )
            }
          >
            
            {"  "}
            <span id="cta-button-desk">
              Ou se preferir, fale agora mesmo com um consultor
            </span><span id="cta-button-mobile">PUBLIQUE CONOSCO</span>
          </button>
        </div>

        <div className="voltar">
          <button
            className="voltar-button"
            onClick={() =>
              window.open(
                "https://publicidadelegal.nsctotal.com.br/"
              )
            }
          >
            {"  "}
            <span id="cta-button-desk">
            Voltar ao publicidade legal
            </span><span id="cta-button-mobile">PUBLIQUE CONOSCO</span>
          </button>
        </div>


      </div>
    );
  }
}

export default Conclusion;
